// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-roast-page-js": () => import("../src/templates/roast-page.js" /* webpackChunkName: "component---src-templates-roast-page-js" */),
  "component---src-templates-phone-case-page-js": () => import("../src/templates/phone-case-page.js" /* webpackChunkName: "component---src-templates-phone-case-page-js" */),
  "component---src-templates-cups-page-js": () => import("../src/templates/cups-page.js" /* webpackChunkName: "component---src-templates-cups-page-js" */),
  "component---src-templates-blog-post-js": () => import("../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-products-cellphone-case-js": () => import("../src/pages/products-cellphone-case.js" /* webpackChunkName: "component---src-pages-products-cellphone-case-js" */),
  "component---src-pages-products-coffee-archive-js": () => import("../src/pages/products-coffee-archive.js" /* webpackChunkName: "component---src-pages-products-coffee-archive-js" */),
  "component---src-pages-products-coffee-js": () => import("../src/pages/products-coffee.js" /* webpackChunkName: "component---src-pages-products-coffee-js" */),
  "component---src-pages-products-cups-js": () => import("../src/pages/products-cups.js" /* webpackChunkName: "component---src-pages-products-cups-js" */),
  "component---src-pages-products-js": () => import("../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */)
}

